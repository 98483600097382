/*
	░█▀▀▄░█▀▀▄░▄▀▀▄░█░▒█░▀█▀░░░▒█▀▀█░█▀▀▄░█▀▀▀░█▀▀
	▒█▄▄█░█▀▀▄░█░░█░█░▒█░░█░░░░▒█▄▄█░█▄▄█░█░▀▄░█▀▀
	▒█░▒█░▀▀▀▀░░▀▀░░░▀▀▀░░▀░░░░▒█░░░░▀░░▀░▀▀▀▀░▀▀▀
*/
.about-page-text-headers{
	color: #151515;
	text-align: left;
	font-size: calc(1rem + 1.5vw);
	font-weight: medium;

	margin-top: 3rem;
	margin-bottom: 2rem;
}

.about-stories-text-headers{
	color: #151515;
	text-align: center;
	font-size: calc(1rem + 1.5vw);
	font-weight: medium;

	margin-top: 3rem;
	margin-bottom: 2rem;
}

/*
	░▒█▀▀▄░▄▀▀▄░█▀▀▄░▀█▀░█▀▀▄░█▀▄░▀█▀░░░▒█▀▀█░█▀▀▄░█▀▀▀░█▀▀
	░▒█░░░░█░░█░█░▒█░░█░░█▄▄█░█░░░░█░░░░▒█▄▄█░█▄▄█░█░▀▄░█▀▀
	░▒█▄▄▀░░▀▀░░▀░░▀░░▀░░▀░░▀░▀▀▀░░▀░░░░▒█░░░░▀░░▀░▀▀▀▀░▀▀▀
*/
#g-recaptcha-response {
	display: block !important;
	position: absolute;
	margin: -50px 0 0 0 !important;
	z-index: -999999;
	opacity: 0;
}

/*
	░▒█░▒█░█▀▀░█░░▄▀▀▄░░░▒█▀▀█░█▀▀▄░█▀▀▀░█▀▀
	░▒█▀▀█░█▀▀░█░░█▄▄█░░░▒█▄▄█░█▄▄█░█░▀▄░█▀▀
	░▒█░▒█░▀▀▀░▀▀░█░░░░░░▒█░░░░▀░░▀░▀▀▀▀░▀▀▀
*/
.image-overlay {
	padding-left: 10px;
	width: 80%;
	position: absolute;
	top: 15%;
	text-align: center;
	color: #fefefe;
}

/*
	░▒█░▒█░▄▀▀▄░█▀▄▀█░█▀▀░░░▒█▀▀█░█▀▀▄░█▀▀▀░█▀▀
	░▒█▀▀█░█░░█░█░▀░█░█▀▀░░░▒█▄▄█░█▄▄█░█░▀▄░█▀▀
	░▒█░▒█░░▀▀░░▀░░▒▀░▀▀▀░░░▒█░░░░▀░░▀░▀▀▀▀░▀▀▀
*/
/* -------------------- Articles -------------------- */
.container {
	position: absolute;
	margin: 0 auto;

	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
}
.container-photo {
	display: flex;
	text-align: left;

	margin-bottom: .25rem;
	float: top;
	height: 70vh;
	max-height: 80vh;
	max-width: 90%;
}
.container-photo img {
	flex-shrink: 0;
	min-width: 100%;
	min-height: 100%
}
 
.statement-overlay {
	padding-left: 10px;
	width: 90%;
	position: absolute;
	margin: 0 auto;
	text-align: center;
	color: #fefefe;
}

.home-page-text-headers{
	color: #151515;
	text-align: center;
	font-size: calc(1rem + 1.5vw);
	font-weight: medium;

	margin-top: 3rem;
	margin-bottom: 2rem;
}

.mission-statement-information {
	margin: 1rem 5rem 0rem 5rem;
	padding: 1rem;
	overflow: hidden;
	text-align: center;
	
	font-size: 15px;
}

.articles{
	color: #151515;
	text-align: left;
}

.thumbnail-image{
	float: left;
	object-fit: contain;
}

.article-header{
	float: left;
	text-align: left;
}

.text-container{
	overflow: hidden;
	line-height: 1.2em;
	width: 100%;
	text-align: left;
}

.read-more-button{
	text-decoration: underline;
	color:#5A5A5A;
	font-size: smaller;
	margin-left: -0.8rem;
}

/*
	░▒█▀▀█░█▀▀▄░░▀░░▄░░░▄░█▀▀▄░█▀▄░█░░█░░░▒█▀▀█░█▀▀▄░█▀▀▀░█▀▀
	░▒█▄▄█░█▄▄▀░░█▀░░█▄█░░█▄▄█░█░░░█▄▄█░░░▒█▄▄█░█▄▄█░█░▀▄░█▀▀
	░▒█░░░░▀░▀▀░▀▀▀░░░▀░░░▀░░▀░▀▀▀░▄▄▄▀░░░▒█░░░░▀░░▀░▀▀▀▀░▀▀▀
*/

/*
	░▒█▀▀▀█░█▀▄░█░░░░▄▀▀▄░█░░█▀▀▄░█▀▀▄░█▀▀░█░░░░░▀░░▄▀▀▄░█▀▀░░░▒█▀▀█░█▀▀▄░█▀▀▀░█▀▀
	░░▀▀▀▄▄░█░░░█▀▀█░█░░█░█░░█▄▄█░█▄▄▀░▀▀▄░█▀▀█░░█▀░█▄▄█░▀▀▄░░░▒█▄▄█░█▄▄█░█░▀▄░█▀▀
	░▒█▄▄▄█░▀▀▀░▀░░▀░░▀▀░░▀▀░▀░░▀░▀░▀▀░▀▀▀░▀░░▀░▀▀▀░█░░░░▀▀▀░░░▒█░░░░▀░░▀░▀▀▀▀░▀▀▀
*/
.scholarship-information h2{
	margin-top: 3rem;
}

/*
	░▒█▀▀█░█▀▀░█▀▀▄░█▀▀░█▀▀▄░█▀▀▄░█░
	░▒█░▄▄░█▀▀░█░▒█░█▀▀░█▄▄▀░█▄▄█░█░
	░▒█▄▄▀░▀▀▀░▀░░▀░▀▀▀░▀░▀▀░▀░░▀░▀▀
*/
.body-page-text {
	margin: 0 auto;
	width: 80%;
	max-width: 80%;
}

.page-information{
	margin: 0 auto;
	padding: 1rem;
	overflow: hidden;
}

/* -------------------- Header -------------------- */
.header {
	overflow: hidden;
	background-color: #f2f3f4;
	padding: 5px 10px;
	margin-bottom: 0.25rem;

	position: relative;
	left: 0;
	top: 0;
	width: 100%;
}
.header a {
	float: left;
	color: #0c2340;
	text-align: center;
	padding: 10px;
	text-decoration: none;
	font-size: 18px; 
	line-height: 25px;
}

.header a.logo {
	font-size: 25px;
	font-weight: bold;
}

.header a:hover {
	text-decoration: underline;
	color: #0c2340;
}

.header-right {
	float: right;
}

@media screen and (max-width: 600px) {
	.header a {
		float: none;
		display: block;
		text-align: left;
	}
	
	.header-right {
		float: none;
	}
}

.header img{
	display: block;
	height: 40px;
	max-width: 200px;
}

/* -------------------- Footer -------------------- */
.footer {
	overflow: hidden;
	background-color: #0c2340;
	padding: 20px 10px;
	margin-top: 5rem;

	position: absolute;
	left: 0;
	bottom: 0;
	height: 100px;
	width: 100%;
	overflow: hidden;
}
.footer a {
	float: left;
	color: #f2f3f4;
	text-align: center;
	padding: 10px;
	text-decoration: none;
	font-size: 18px; 
	line-height: 25px;
}

.footer a.logo {
	font-size: 25px;
	font-weight: bold;
}
.footer a.logo:hover {
	text-decoration: none;
}

.footer-right {
	float: right;
}

.copyright-footer{
	float: left;
	font-size: 0.5rem;
	color: #b9b9b9;
	margin-left: 10px;
}

.logo-text{
	font-family: "Times New Roman", Times, serif;
	font-size: 25px;
}

@media screen and (max-width: 600px) {
	.footer a {
		float: none;
		text-align: left;
	}

	.footer-right {
		float: none;
	}
}

.registered-trademark-symbol{
	font-size: 0.70rem;
}

/* Use media queries to change styles based on screen size */
@media only screen and (max-width: 600px) {
	/* Make your website's content container narrower on smaller screens */
	.content {
	  margin: 0 auto;
	  text-size-adjust: auto;
	}
} 